import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from'@mui/styles';
import useScreenSize from 'components/customHooks/useScreenSize';
import data from './data';

const RepscoreComponent = () => {
  const screenSize = useScreenSize();
  const classes = useStyles();

  return (
    <Box className={classes.container} role="region" aria-label="audience-section">
      <Typography align="center" className={classes.sectionTitle}>
        Team-Based Reputation Scores
      </Typography>
      <Grid container direction="row" justifyContent="space-between">
        {data.map(({ title, image, description }) => (
          <Grid
            item
            xs={screenSize === 'largeScreen' ? 4 : 12}
            key={title}
            className={classes.card}
            role="article"
          >
            <img src={image} alt={title} className={classes.image} />
            <Typography component="h2" className={classes.title} role="heading">
              {title}
            </Typography>
            <Typography className={classes.description}>
              {description}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default RepscoreComponent;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '3.75rem 0 5rem',
    maxWidth: '1170px',
    margin: 'auto',
  },
  sectionTitle: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '34px',
    lineHeight: '150%',
    letterSpacing: '.15px',
    color: '#001B11',
    marginBottom: '2.75rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
      padding: '0 .5rem',
    },
  },
  card: {
    textAlign: 'center',
    padding: '0 1rem',
    // @TODO 1140 should be updated to a constant variable
    [theme.breakpoints.down(1140)]: {
      '&:not(:last-of-type)': {
        paddingBottom: '3rem',
      },
    },
  },
  image: {
    height: '79px',
    width: '78px',
  },
  title: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: 22,
    color: '#001B11',
    letterSpacing: '.15px',
    whiteSpace: 'nowrap',
    margin: '2rem 0 1rem',
  },
  description: {
    textAlign: 'center',
    lineHeight: '27px',
    fontSize: '18px',
    color: 'rgba(0, 27, 17, 0.7)',
    maxWidth: 320,
    margin: '0 auto',
  },
}));
